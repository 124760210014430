import './App.css';
import Main from './Component/Main';


function App() {
  return (
  <div className="App">
    <Main />
  </div>

  );
}

export default App;
